var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "score-table" }, [
    _vm.info.mainResult
      ? _c("div", { staticClass: "part" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.info.mainResult,
                    "header-cell-style": {
                      textAlign: "center",
                      background: "#FAFAFA"
                    },
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "评测指标", prop: "name", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "满分",
                      prop: "grossScore",
                      align: "center"
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "系统评定得分",
                      prop: "score",
                      align: "center"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e(),
    _vm.info.otherResult
      ? _c("div", { staticClass: "part" }, [
          _vm._m(1),
          _c(
            "div",
            { staticClass: "content" },
            [
              _c(
                "el-table",
                {
                  staticStyle: { width: "100%" },
                  attrs: {
                    data: _vm.info.otherResult,
                    "header-cell-style": {
                      textAlign: "center",
                      background: "#FAFAFA"
                    },
                    "cell-style": { textAlign: "center" }
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "评测指标", prop: "name", align: "center" }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      label: "系统评定得分",
                      prop: "score",
                      align: "center"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("农户信用评测指标")])
    ])
  },
  function() {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "title" }, [
      _c("span", [_vm._v("其他补充指标")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }