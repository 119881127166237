
import { Component, Vue, Prop } from 'vue-property-decorator'
import bgSrc from '@/assets/images/eitd.png'
@Component({
  components: {}
})
export default class ProInfo extends Vue {
  @Prop({ default: false })
  isHide?: boolean
  @Prop({ default: () => {} })
  info: any
  @Prop({ default: () => {} })
  rating: any
  @Prop() black?: any
  @Prop({ default: true }) openFraction?: boolean
  @Prop({ default: false }) approval?: boolean
  // @Prop({ default: false }) isChangeSession?: boolean
  @Prop({
    default: () => {
      return []
    }
  })
  creditRatingOptions?: any
  @Prop({ default: false }) isChange?: boolean
  @Prop({ default: false }) creditRatingDis?: boolean
  @Prop({ default: false }) priorityDis?: boolean
  @Prop({ default: false }) isEdit?: boolean
  @Prop({
    default: () => {
      return []
    }
  })
  isAssessOptions: any
  private bgImg: any = bgSrc
  private managerGrade: any = ''
  private getApprovalStatus(status: any) {
    let returnVal: any = status
    if (this.isAssessOptions && this.isAssessOptions.length) {
      for (let i = 0; i < this.isAssessOptions.length; i++) {
        if (status == this.isAssessOptions[i].value) {
          returnVal = this.isAssessOptions[i].name
          break
        }
      }
    }
    return returnVal
  }
  private intoDetails() {
    // this.isChangeSession && sessionStorage.setItem('farmerCreditBasic', JSON.stringify(this.info))
    // this.isChangeSession && this.$emit('update:isChangeSession', false)
    this.$router.push({
      name: 'FarmerInfoDetail',
      params: { type: '1', farmersInfoId: this.info.farmerId }
    })
  }
  private openCreditRatingEdit() {
    this.managerGrade = this.info.creditRatingId && this.info.creditRating ? this.info.creditRatingId + ',' + this.info.creditRating : ''
    this.$emit('update:isEdit', true)
  }
  private determineEdit() {
    this.$emit('determineEdit', this.managerGrade)
  }
  private changePriorityEmit() {
    this.$emit('priorityEmit')
  }
  private changePriority() {
    this.info.priority == '1' ? (this.info.priority = 2) : (this.info.priority = 1)
  }
}
