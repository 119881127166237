import request from '@/utils/request'
// 信用评定管理

// ------------个人中心------------
// 信用指标信息查询
export function getCreditIndicatorInfoList(data: Object) {
  return request({
    url: '/creditIndicatorInfo/list',
    method: 'POST',
    data: data
  })
}

// 编辑信用指标类别 或 名称 或 评分条件
export function editCategory(data: Object) {
  return request({
    url: '/creditIndicatorInfo/editCategory',
    method: 'POST',
    data: data
  })
}

// 删除信用指标类别 或 名称 或 评分条件
export function delCategory(data: Object) {
  return request({
    url: '/creditIndicatorInfo/delCategory',
    method: 'POST',
    data: data
  })
}

//新增信用指标类别 
export function addCategory(data: Object) {
  return request({
    url: '/creditIndicatorInfo/addCategory',
    method: 'POST',
    data: data
  })
}

//新增信用名称 或 评分条件
export function addCategoryName(data: Object) {
  return request({
    url: '/creditIndicatorInfo/addCategoryName',
    method: 'POST',
    data: data
  })
}
//分页查询信用等级表
export function creditRatingPage(data: Object) {
  return request({
    url: '/creditRating/page',
    method: 'POST',
    data: data
  })
}
//删除信用等级
export function delRating(data: Object) {
  return request({
    url: '/creditRating/delRating',
    method: 'POST',
    data: data
  })
}
//获取信用等级符号下拉和
export function creditRatingDrop(data: Object) {
  return request({
    url: '/tablePropertyConfigInfo/creditRatingDrop',
    method: 'POST',
    data: data
  })
}
//添加信用等级
export function addRating(data: Object) {
  return request({
    url: '/creditRating/addRating',
    method: 'POST',
    data: data
  })
}
//查看信用等级详情
export function creditRatingDetails(data: Object) {
  return request({
    url: '/creditRating/details',
    method: 'POST',
    data: data
  })
}
//编辑信用等级
export function editRating(data: Object) {
  return request({
    url: '/creditRating/editRating',
    method: 'POST',
    data: data
  })
}



// ------------信用评定权限-----------------
// 评定人列表
export function creditAssessorList(data: Object) {
  return request({
    url: '/creditAssessorRel/page',
    method: 'POST',
    data: data
  })
}
// 评定人删除
export function creditAssessorDel(data: Object) {
  return request({
    url: '/creditAssessorRel/delAssessor',
    method: 'POST',
    data: data
  })
}
// 角色列表
export function selectRoles(data: Object) {
  return request({
    url: '/creditAssessorRel/selectRoles',
    method: 'POST',
    data: data
  })
}
// 角色-用户列表
export function selectRoleUsers(data: Object) {
  return request({
    url: '/creditAssessorRel/selectRoleUsers',
    method: 'POST',
    data: data
  })
}
// 用户-被评定人列表
export function userBeAssessorList(data: Object) {
  return request({
    url: '/creditAssessorRel/userRoleBeAssessorPage',
    method: 'POST',
    data: data
  })
}
// 添加评定人
export function creditAssessorAdd(data: Object) {
  return request({
    url: '/creditAssessorRel/addAssessorAndBeAssessor',
    method: 'POST',
    data: data
  })
}
// 编辑-再添加评定人
export function creditAssessorEdit(data: Object) {
  return request({
    url: '/creditAssessorRel/editAssessorAndBeAssessor',
    method: 'POST',
    data: data
  })
}
// 评定人详情-评定人
export function creditAssessorDetail(data: Object) {
  return request({
    url: '/creditAssessorRel/selectRoleUser',
    method: 'POST',
    data: data
  })
}
// 评定人详情-被评定人
export function creditBeAssessorDetail(data: Object) {
  return request({
    url: '/creditAssessorRel/beAssessorPage',
    method: 'POST',
    data: data
  })
}
// 删除被评定人
export function creditBeAssessorDel(data: Object) {
  return request({
    url: '/creditAssessorRel/delBeAssessor',
    method: 'POST',
    data: data
  })
}
// 添加全部被评定人
export function creditBeAssessorAdd(data: Object) {
  return request({
    url: '/creditAssessorRel/userRoleBeAssessorList',
    method: 'POST',
    data: data
  })
}

// ------------农户信用评定-----------------

// 获取农户评定列表
export function getAssessmentHistoryList(data: Object) {
  return request({
    url: '/creditUserAssessInfo/page',
    method: 'POST',
    data: data
  })
}

// 获取未评定模板信息
export function getCreditPaneInfo(data: Object) {
  return request({
    url: '/creditUserAssessInfo/indicators',
    method: 'POST',
    data: data
  })
}

// 农户信用评定
export function doCredit(data: Object) {
  return request({
    url: '/creditUserAssessInfo/assess',
    method: 'POST',
    data: data
  })
}

// 获取已评定详情
export function getCreditDetails(data: Object) {
  return request({
    url: '/creditUserAssessInfo/detail',
    method: 'POST',
    data: data
  })
}

// 获取未评定黑名单信息
export function getBlackInfo(data: Object) {
  return request({
    url: '/creditUserAssessInfo/black',
    method: 'POST',
    data: data
  })
}

// 切换展示优先级
export function switchPriority(data: Object) {
  return request({
    url: '/creditUserAssessInfo/switchPriorityDisplay',
    method: 'POST',
    data: data
  })
}

// 手动评定
export function manualSave(data: Object) {
  return request({
    url: '/creditUserAssessInfo/manualSave',
    method: 'POST',
    data: data
  })
}

// 自动评定
export function creditTaskAdd(data: Object) {
  return request({
    url: '/creditAssessTask/add',
    method: 'POST',
    data: data
  })
}

// ------------信用查看审批模块-----------------

// 获取信用查看审批列表
export function getCreditApprovalList(data: Object) {
  return request({
    url: '/creditViewApproval/page',
    method: 'POST',
    data: data
  })
}

// 信用查看审批
export function creditApproval(data: Object) {
  return request({
    url: '/creditViewApproval/approval',
    method: 'POST',
    data: data
  })
}

// ------------农户信用-----------------
// 是否允许查看已评定信息
export function creditInfoShow(data: Object) {
  return request({
    url: '/creditUserAssessInfo/show',
    method: 'POST',
    data: data
  })
}

// 农户信用列表
export function getAssessedPersonList(data: Object) {
  return request({
    url: '/creditAssessorRel/beAssessPage',
    method: 'POST',
    data: data
  })
}

// ------------新增指标(表格) --高峰-----------------
// 查看
export function evaluationInfo(data: Object) {
  return request({
    url: '/evaluation/info',
    method: 'POST',
    data: data
  })
}

// 保存
export function evaluationSave(data: Object) {
  return request({
    url: '/evaluation/save',
    method: 'POST',
    data: data
  })
}

// ------------自动评定进度-----------------
// 获取自动评定进度列表
export function autoAssessmentProgressList(data: Object) {
  return request({
    url: '/creditAssessTask/page',
    method: 'POST',
    data: data
  })
}

// 删除任务
export function deleteWork(data: Object) {
  return request({
    url: '/creditAssessTask/del',
    method: 'POST',
    data: data
  })
}